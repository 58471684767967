import { ref, watch, computed, reactive } from '@vue/composition-api'
import store, {globalStore} from '@/store'
import { title } from '@core/utils/filter'
import useJwt from '@/auth/jwt/useJwt'
import  routes from '@/@core/utils/api-routes'
import { paginateArray } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { rider } from '@/store/app/rider.store'

const riders = ref([])
const filteredRiders = ref([])
const nearbyRiders = ref([])
const onlineRiders = ref([])

export default function useDriversList() {
  // Use toast
  const toast = useToast()

  const refDriverListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    'show_details',
    { key: 'id', stickyColumn: true, sortable: true },
    { key: 'driver', sortable: false },
    { key: 'profile.emailAddress', label: 'Email', sortable: false },
    { key: 'profile.nin', label: 'NIN', sortable: false },
    // { key: 'ride', sortable: false },
    // { key: 'documents', sortable: false },
    { key: 'profile.residentialAddress', label: 'Address', sortable: true },
    // { key: 'profile.gender', label: 'Gender', sortable: true },
    { key: 'isVerified', label: 'Verified', sortable: true },
    { key: 'isSuspended', label: 'Status', sortable: true },
    { key: 'createdAt', label: 'Signedup On', sortable: true},
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalDrivers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const rideTypeFilter = ref(null)
  const verificationFilter = ref(null)
  const suspensionFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refDriverListTable.value ? refDriverListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDrivers.value,
    }
  })

  const refetchData = () => {
    refDriverListTable.value.refresh()
  }
  
  const refreshData = () => {
    drivers.value = []
    fetchDrivers()
  }

  watch([currentPage, perPage, searchQuery, rideTypeFilter, verificationFilter, suspensionFilter], () => {
    const queryLowered = searchQuery.value.toLowerCase()
    const filteredData = riders.value.filter(
      rider =>
        rider.ride?.type?.name?.toLowerCase() === (rideTypeFilter.value?.toLowerCase() || rider?.ride?.type?.name?.toLowerCase()) &&
        rider.verificationStatus.toLowerCase() === (verificationFilter.value?.toLowerCase() || rider.verificationStatus.toLowerCase()) &&
        rider.suspensionStatus.toLowerCase() === (suspensionFilter.value?.toLowerCase() || rider.suspensionStatus.toLowerCase()) &&
       ( rider.fullName?.toLowerCase().includes(queryLowered) || rider.profile?.phone?.toLowerCase().includes(queryLowered) ||
       rider.profile?.emailAddress?.toLowerCase().includes(queryLowered) || rider.profile?.nin?.toLowerCase().includes(queryLowered) ||
       rider.profile?.residentialAddress?.toLowerCase().includes(queryLowered) || rider?.id == queryLowered) 
    )
    
    filteredRiders.value = paginateArray(filteredData, perPage.value, currentPage.value)
    totalDrivers.value = filteredData.length
    refetchData()
  })

  function setRider(rider) {
    riders.value = riders.value.filter(x => x.token != rider.token);
    riders.value.push(rider);
    riders.value.sort(function (a, b) {
      return a.id - b.id;
    });
  }

  function getRider(id) {
    return riders.value.find(x => (x.id == id || x.token == id));
  }

  const fetchDrivers = (ctx) => {
    if(riders.value?.length) {
      // callback(riders.value);
      return;
    }
    useJwt.axiosIns.get(routes.RIDERS)
      .then(({data}) => {
        let drivers = data

        drivers.forEach((d) => {
          d.verificationStatus = d.isVerified ?  "Verified":"Not verified"
          d.suspensionStatus = d.isSuspended ?  "Suspended":"Active"
          d.fullName = `${d.profile?.firstName} ${d.profile?.lastName}`
        });

        riders.value = drivers.sort((a, b) => a.updatedAt - b.updatedAt);
        filteredRiders.value = paginateArray(riders.value, perPage.value, currentPage.value)

        totalDrivers.value = drivers.length
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching drivers list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchNearbyDrivers = (tripToken) => {
    useJwt.axiosIns.get(routes.NEARBY_RIDERS+"/"+tripToken)
      .then(({data}) => {

        data.forEach((d) => {
          d.rider.fullName = `${d.rider.profile?.firstName} ${d.rider.profile?.lastName}`
        });

        nearbyRiders.value = data;
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching nearby riders: ' + response?.data?.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const updateVerificationStatus = (token) => {
    useJwt.axiosIns.post(`${routes.UPDATE_VERIF_STATUS}/${token}/toggle-verification`, {})
      .then(({data}) => {
        if(data) {
          let rider = getRider(token);
          if(rider) {
            rider.isVerified = !rider.isVerified;
            rider.verificationStatus = rider.isVerified ?  "Verified":"Not verified"
            setRider(rider)
          }
        }
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating rider verification: ' + response?.data?.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const updateAccountStatus = (token) => {
    useJwt.axiosIns.post(`${routes.UPDATE_VERIF_STATUS}/${token}/toggle-suspension`, {})
      .then(({data}) => {
        if(data) {
          let rider = getRider(token);
          if(rider) {
            rider.isSuspended = !rider.isSuspended
            rider.suspensionStatus = rider.isSuspended ?  "Suspended" : "Active"
            setRider(rider)
          }
        }
      })
      .catch(({response}) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating rider verification: ' + response?.data?.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveDriverRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveRideType = ride => {
    return ride.type?.name;
  }

  const resolveDriverRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }
  
  const resolveRideTypeVariant = role => {
    if (role.type?.name === 'car') return 'primary'
    if (role.type?.name === 'truck') return 'warning'
    if (role.type?.name === 'bike') return 'success'
    if (role.type?.name === 'editor') return 'danger'
    if (role.type?.name === 'admin') return 'info'
    return 'primary'
  }

  const resolveDriverStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveSuspendedStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'suspended') return 'secondary'
    return 'primary'
  }

  const resolveVerifiedStatusVariant = status => {
    if (status === 'Not verified') return 'warning'
    if (status === 'Verified') return 'success'
    return 'primary'
  }

  const resolvePhoto = photo => {
    return photo?.url ?? ""
  }

  return {
    fetchDrivers,
    updateVerificationStatus,
    updateAccountStatus,
    fetchNearbyDrivers,
    tableColumns,
    perPage,
    currentPage,
    totalDrivers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDriverListTable,

    resolveDriverRoleVariant,
    resolveDriverRoleIcon,
    resolveDriverStatusVariant,
    resolveSuspendedStatusVariant,
    resolveVerifiedStatusVariant,
    resolveRideTypeVariant,
    resolveRideType,
    refetchData,
    refreshData,
    resolvePhoto,

    // Extra Filters
    rideTypeFilter,
    verificationFilter,
    suspensionFilter,
    drivers: computed(() => filteredRiders.value),
    nearbyRiders: computed(() => nearbyRiders.value)
  }
}
