import { ref, watch, computed, reactive } from '@vue/composition-api'
import store, {globalStore} from '@/store'
import { title } from '@core/utils/filter'
import useJwt from '@/auth/jwt/useJwt'
import  routes from '@/@core/utils/api-routes'
import { paginateArray, sortCompare } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const trips = ref([])
const filteredTrips = ref([])

export default function useSchedule() {
  // Use toast
  const toast = useToast()

  const refTripTable = ref(null)

  // Table Handlers
  const tableColumns = [
    'show_details',
    { key: 'id', sortable: true },
    { key: 'scheduleTime', label: 'Scheduled On', sortable: true },
    { key: 'user', sortable: true },
    { key: 'rider', sortable: true },
    { key: 'startLocationAddress', label: 'Pickup', sortable: true },
    { key: 'distance', sortable: true },
    { key: 'rideType', sortable: true },  
    { key: 'payment', sortable: true },
    { key: 'status', sortable: true},
    { key: 'createdAt', label: 'Booked On', sortable: true},
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalTrips = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const rideFilter = ref(null)
  const paymentFilter = ref(null)
  const statusFilter = ref(null)


  const dataMeta = computed(() => {
    const localItemsCount = refTripTable.value ? refTripTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTrips.value,
    }
  })

  const refetchData = () => {
    refTripTable.value.refresh()
  }
 
  const refreshData = () => {
    trips.value = []
    fetchTrips()
  }

  watch([currentPage, perPage, searchQuery, rideFilter, paymentFilter, statusFilter], () => {
     const queryLowered = searchQuery.value.toLowerCase()
    const filteredData = trips.value.filter(
      trip =>
        trip.status?.toLowerCase() === (statusFilter.value?.toLowerCase() || trip.status.toLowerCase()) &&
        trip.rideType?.toLowerCase() === (rideFilter.value?.toLowerCase() || trip.rideType.toLowerCase()) &&
        trip.payment?.type?.toLowerCase() === (paymentFilter.value?.toLowerCase() || trip.payment?.type.toLowerCase()) &&
       ( trip.status?.toLowerCase().includes(queryLowered)  ||
        trip.rider?.profile.firstName.toLowerCase().includes(queryLowered) || 
        trip.rider?.profile.lastName.toLowerCase().includes(queryLowered))
        )
    filteredTrips.value = paginateArray(filteredData, perPage.value, currentPage.value)
    totalTrips.value = filteredData.length
    refetchData()
  })

  function updateTrip(trip) {
    trips.value = trips.value.filter(x => x.id != trip.id);
    trips.value.push(trip);
    trips.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredTrips.value = paginateArray(trips.value, perPage.value, currentPage.value)
  }


  const fetchTrips = (ctx) => {
    if(trips.value?.length) {
      return;
    }
    useJwt.axiosIns.get(routes.SCHEDULED)
      .then(({data}) => {
        data.forEach((d) => {
          if(d.status == 'Created' || d.status == 'Rider-Found') {
              d.started = false
          }else if(d.status != 'Trip-Ended') {
              d.started = true
          }
          d.status = d.status.replace('-', ' ')
        });
        trips.value = data.sort((a, b) => a.updatedAt - b.updatedAt);
        filteredTrips.value = paginateArray(trips.value, perPage.value, currentPage.value)
        totalTrips.value = data.length
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching trips',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const setRider = ({rider, trip}) => {
    console.log(rider,trip)
      return useJwt.axiosIns.post(routes.SET_RIDER, {rider, trip})
      .then(({data}) => {
          console.log(data)
          updateTrip(data)
          toast({
            component: ToastificationContent,
            props: {
              title: "Trip rider updated",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          return data
      })
      .catch(({response}) => {
        console.log(response);
        toast({
            component: ToastificationContent,
            props: {
              title: "Error updating trip ",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
      });
  }

  const updateDeliveryStatus = (trip) => {
      let route = trip.started ? routes.END_TRIP : routes.START_TRIP
      return useJwt.axiosIns.post(route, {trip})
      .then(({data}) => {
          updateTrip(data)
          toast({
            component: ToastificationContent,
            props: {
              title: "Trip status updated",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          return data
      })
      .catch(({response}) => {
        console.log(response);
        toast({
            component: ToastificationContent,
            props: {
              title: "Error updating trip ",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
      });
  }

  
  const resolvePhoto = photo => {
    return photo?.url ?? ""
  }

  return {
    fetchTrips,
    setRider,
    updateDeliveryStatus,
    tableColumns,
    perPage,
    currentPage,
    totalTrips,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTripTable,
    refetchData,
    refreshData,
    resolvePhoto,

    // Extra Filters
    rideFilter,
    paymentFilter,
    statusFilter,
    scheduledCount: computed(() => trips.value.length),
    trips: computed(() => filteredTrips.value)
  }
}
