import { ref, watch, computed, reactive } from '@vue/composition-api'
import store, {globalStore} from '@/store'
import { title } from '@core/utils/filter'
import useJwt from '@/auth/jwt/useJwt'
import  routes from '@/@core/utils/api-routes'
import { paginateArray, sortCompare } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const ridersOnline = ref([])
const filteredOnlineRiders = ref([])

export default function useOnlineRider() {
  // Use toast
  const toast = useToast()

  const refRidersOnlineTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'riderName', label: 'Rider', sortable: true },
    { key: 'location', sortable: true },
    // { key: 'coordinates', sortable: true },
    { key: 'rideType', sortable: true },
    // { key: 'onTrip', sortable: true },
    { key: 'lastSeen' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalRidersOnline = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)


  const dataMeta = computed(() => {
    const localItemsCount = refRidersOnlineTable.value ? refRidersOnlineTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRidersOnline.value,
    }
  })

  const refetchData = () => {
    refRidersOnlineTable.value.refresh()
  }
 
  const refreshData = () => {
    ridersOnline.value = []
    fetchRidersOnline()
  }

  watch([currentPage, perPage, searchQuery], () => {
     const queryLowered = searchQuery.value.toLowerCase()
    const filteredData = ridersOnline.value.filter(
      rider => rider.riderName.toLowerCase().includes(queryLowered) || rider.location.toLowerCase().includes(queryLowered))
    filteredOnlineRiders.value = paginateArray(filteredData, perPage.value, currentPage.value)
    totalRidersOnline.value = filteredData.length
    refetchData()
  })


  const fetchRidersOnline = (ctx) => {
    // if(ridersOnline.value?.length) {
    //   return;
    // }
    useJwt.axiosIns.get(routes.RIDERS_ONLINE)
      .then(({data}) => {
          ridersOnline.value = data
          filteredOnlineRiders.value = paginateArray(ridersOnline.value, perPage.value, currentPage.value)
          totalRidersOnline.value = data.length
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching riders online',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchRidersOnline,
    refetchData,
    refreshData,
    tableColumns,
    perPage,
    currentPage,
    totalRidersOnline,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRidersOnlineTable,

    // Extra Filters
    onlineCount: computed(() => ridersOnline.value.length),
    ridersOnline: computed(() => filteredOnlineRiders.value)
  }
}
